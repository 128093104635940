import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import ReactCodeInput from "react-code-input";
import FormControl from "@material-ui/core/FormControl";
import { useSelector } from "react-redux";
import { useInterval } from "../../../../../utils/useInterval";
import { useLoginStyles } from "../../../styles/LoginStyles";
import { getLabels } from "../../../labels";
import moment from "moment";

const OTPTimer = ({ timerCompleted, text, m, s = 59 }) => {
  // const [sec, setSec] = useState(s);
  const [sec, setSec] = useState(m >= 0 ? s : 0);
  // const [min, setMin] = useState(m);
  const [min, setMin] = useState(m >= 0 ? m : 0);
  const [delay, setDelay] = useState(1000);
  const classes = useLoginStyles();
  useInterval(() => {
    if (sec !== 0) {
      setSec((c) => c - 1);
    } else if (sec === 0 && min !== 0) {
      setSec(59);
      setMin((c) => c - 1);
    } else {
      setSec(0);
      setMin(0);
      timerCompleted();
      setDelay(null);
    }
  }, delay);
  if (text)
    return (
      <Box display="flex" justifyContent="center">
        <Typography
          variant="body2"
          color="primary"
          className={classes.otpLabel}
        >
          {text} &nbsp;
        </Typography>
        <Typography variant="body2" color="error" className={classes.otpLabel}>
          {(min <= 9 ? "0" : "") +
            min +
            "m : " +
            (sec <= 9 ? "0" : "") +
            sec +
            "s"}{" "}
        </Typography>
      </Box>
    );
  else
    return (
      <Typography variant="body2" color="error" className={classes.otpLabel}>
        Re-send in &nbsp;{(sec <= 9 ? "0" : "") + sec + "s"}
      </Typography>
    );
};

export const OTPComp = ({
  otp,
  setOtp,
  otpLengthErr,
  onResend,
  timerCompleted,
  showResend,
  setShowResend,
}) => {
  const locale = useSelector(({ core }) => core.locale || "en");
  const classes = useLoginStyles();
  const labels = getLabels(locale);
  const phone = useSelector(({ core }) => core.phone);
  const expiresIn = useSelector(({ core }) =>
    core.expiresIn ? moment(core.expiresIn) : ""
  );
  const resendAvailableIn = useSelector(({ core }) =>
    core.resendAvailableIn ? moment(core.resendAvailableIn) : ""
  );
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");

  const [resendSec, setResendSec] = useState("");

  useEffect(() => {
    setMinutes("");
    setSeconds("");
    if (expiresIn) {
      let duration = expiresIn.diff(moment(), "s");
      let minDur = Math.floor(duration / 60);
      let secDur = duration - minDur * 60;
      setMinutes(minDur);
      setSeconds(secDur);
    }
  }, [expiresIn]);

  useEffect(() => {
    setResendSec("");
    setShowResend(false);
    if (resendAvailableIn) {
      let rDuration = resendAvailableIn.diff(moment(), "s");
      if (rDuration > 0) setResendSec(rDuration);
      else setShowResend(true);
    }
  }, [resendAvailableIn]);

  return (
    <>
      {minutes && seconds ? (
        <OTPTimer
          timerCompleted={timerCompleted}
          text={labels.otpExpiresIn}
          m={minutes}
          s={seconds}
        />
      ) : (
        <Box display="flex"></Box>
      )}
      <Grid item>
        <br />
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.otpLabel}
          >
            Please Enter OTP you received on
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            className={classes.resendCode}
          >
            {phone}
            {/* {[...new Array(phone.toString().slice(0, -4).length)]
              .map(i => "*")
              .join("") + phone.toString().slice(-4)} */}
          </Typography>
        </Box>
      </Grid>
      <br />
      <Grid container item spacing={1}>
        <FormControl className={classes.formControl} fullWidth>
          <ReactCodeInput
            onChange={(otp) => {
              setOtp(otp);
            }}
            className={classes.reactCodeInput}
            inputStyle={{
              margin: "8px",
              color: "#3D4548",
              MozAppearance: "textfield",
              width: "40px",
              borderRadius: "4px",
              fontSize: "20px",
              height: "40px",
              textAlign: "center",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
            type="number"
            value={otp}
            fields={5}
            autoFocus
            separator={<span>-</span>}
          />
          {otpLengthErr && (
            <Typography variant="body2" align="center" color="error">
              {" "}
              {otpLengthErr}{" "}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ padding: "0px 8px" }}
        >
          <Typography variant="body2">Did not receive code?</Typography>
          {resendSec && resendSec > 0 && (
            <OTPTimer
              timerCompleted={() => setShowResend(true)}
              m={0}
              s={resendSec}
            />
          )}
          {showResend && (
            <Typography
              variant="body2"
              color="primary"
              className={classes.resendCode}
              onClick={onResend}
            >
              {labels.reSendCode}
            </Typography>
          )}
        </Box>
      </Grid>
    </>
  );
};
