import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { getLabels } from "../../labels";
import { useAuthLayoutStyles } from "../../styles/AuthLayoutStyles.js";
import { useSelector } from "react-redux";

export const AuthLayout = ({ children, belowForm, vErr, lastDiv = true }) => {
  const classes = useAuthLayoutStyles();
  const locale = useSelector(({ core }) => core.locale || "ar");
  const labels = getLabels(locale);

  const passLoginError = useSelector(({ core }) => core?.passLoginError);
  const vehicleLoginError = useSelector(({ core }) => core?.vehicle?.error);
  const invalidOtpError = useSelector(({ core }) => core.invalidOtpError);
  const forgetPwSuc = useSelector(({ core }) => core.forgetPwSuc);
  const forgetPwErr = useSelector(({ core }) => core.forgetPwErr);
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.pageContainer}
      >
        <Grid item>
          <img
            src={"/assets/svg/rehlati-logo.svg"}
            alt="eMushrif Logo"
            className={classes.logo}
          />
          {Object.keys(passLoginError || {})?.length !== 0 && (
            <Paper
              className={classNames(classes.loginFormPaper, classes.loginError)}
            >
              <Typography color="error">
                {passLoginError}
                {/* {labels[passLoginError]} */}
              </Typography>
            </Paper>
          )}
          {vErr && vehicleLoginError && (
            <Paper
              className={classNames(classes.loginFormPaper, classes.loginError)}
            >
              <Typography color="error">
                {vehicleLoginError}
                {/* {labels[passLoginError]} */}
              </Typography>
            </Paper>
          )}
          {invalidOtpError && (
            <Paper
              className={classNames(classes.loginFormPaper, classes.loginError)}
            >
              <Typography color="error">{labels[invalidOtpError]}</Typography>
            </Paper>
          )}
          {forgetPwSuc && (
            <Paper
              className={classNames(classes.loginFormPaper, classes.loginError)}
            >
              <Typography color="primary">{forgetPwSuc}</Typography>
            </Paper>
          )}
          {forgetPwErr && (
            <Paper
              className={classNames(classes.loginFormPaper, classes.loginError)}
            >
              <Typography color="error">{forgetPwErr}</Typography>
            </Paper>
          )}
          <Paper className={classes.loginFormPaper}>
            <form
              className={classes.loginForm}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {children}
            </form>
          </Paper>
          {belowForm}
          <img
            src={"/assets/png/pdo-logo.png"}
            className={classes.logoFooter}
          />
        </Grid>
      </Grid>

      {lastDiv ? <div className={classes.lastDiv} /> : <></>}
    </div>
  );
};
