import { makeStyles } from "@material-ui/core/styles";
import header from "../assets/header.svg";

export const useAuthLayoutStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    display: "flex",
    backgroundImage: `url(${header})`,
    backgroundRepeat: "no-repeat",
    // backgroundSize: "contain",
    backgroundPositionX: "right",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "contain",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  field: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    // width: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    minHeight: 70,
    // minHeight: '2.5em',
    zIndex: "unset",
  },
  formControlLogin: {
    minHeight: "auto",
  },
  button: {
    padding: "15px 20px",
  },
  fullHeight: {
    height: "100%",
  },
  pageContainer: {
    marginTop: 170,
    marginBottom: 60,
    height: "100%",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      marginTop: 35,
    },
    // background: theme.palette.background.default,
  },
  logo: {
    margin: "50px auto 30px",
    display: "block",
    width: 250,
  },
  logoFooter: {
    margin: "20px auto 0",
    display: "block",
    height: 70,
  },
  loginFormPaper: {
    boxSizing: "content-box",
    width: "35vh",
    margin: "0 auto",
    padding: "1.5em",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  loginError: {
    marginBottom: "20px",
  },
  loginForm: {
    width: "100%",
  },
  loginRegister: {
    textAlign: "center",
    marginTop: 15,
  },
  lastDiv: {
    background: theme.palette.primary.main,
    position: "absolute",
    bottom: 0,
    height: 20,
    width: "100%",
    alignSelf: "center",
  },
  errorBoundaryContainer: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  yellow: {
    color: "#ffa600",
  },
  logoFooterPosition: {
    position: "absolute",
    bottom: "5%",
  },
  whiteBg: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 2px 0px #cec2c2",
  },
}));
