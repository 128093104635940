import { makeStyles } from "@material-ui/core/styles";

export const useLoginStyles = makeStyles((theme) => ({
  register: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  register1: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    textDecoration: "none",
    cursor: "pointer",
  },
  forgotPassword: {
    textAlign: "right",
    alignSelf: "flex-end",
    marginBottom: theme.spacing(2),
    color: theme.palette.error.main,
    cursor: "pointer",
    "& a": {
      // textDecoration: "none",
      color: "unset",
    },
  },
  loginUsingAzure: {
    textAlign: "right",
    alignSelf: "flex-end",
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    cursor: "pointer",
    "& a": {
      // textDecoration: "none",
      color: "unset",
    },
  },
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  field: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    // width: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    minHeight: 70,
    // minHeight: '2.5em',
    zIndex: "unset",
  },
  formControlLogin: {
    minHeight: "auto",
  },
  button: {
    padding: "15px 20px",
  },
  fullHeight: {
    height: "100%",
  },
  pageContainer: {
    height: "100%",
    textAlign: "left",
    background: theme.palette.background.default,
  },
  rightPane: {
    height: "100%",
    textAlign: "left",
  },
  leftPane: {
    backgroundColor: "#f1f3f5",
    height: "100%",
    textAlign: "center",
  },
  logo: {
    margin: "0 auto 30px",
    display: "block",
    width: 250,
  },
  logoFooter: {
    margin: "20px auto 0",
    display: "block",
    height: 70,
  },
  loginFormPaper: {
    boxSizing: "content-box",
    width: "35vh",
    margin: "0 auto",
    padding: "1.5em",
  },
  loginError: {
    width: "66%",
    margin: "0 auto 20px",
    padding: "1.5em",
  },
  loginForm: {
    width: "100%",
  },
  illustration: {
    width: "50vh",
    marginBottom: 30,
  },
  messagesContainer: {
    maxWidth: "85%",
    display: "inline-grid",
  },
  onboardMessage: {
    // fontSize: 50,
  },
  loginRegister: {
    textAlign: "center",
    marginTop: 15,
  },
  isPending: {
    padding: theme.spacing(3, 0),
  },
  pendingApproval: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  pendingRegistrationMessage: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  otpLabel: {
    paddingLeft: theme.spacing(1),
  },
  otpFields: {
    padding: theme.spacing(1),
    background: "red",
  },
  resendCode: {
    cursor: "pointer",
    fontWeight: 500,
  },
  reactCodeInput: {
    textAlign: "center",
    '& input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button': {
      "-webkit-appearance": "none",
      "  -moz-appearance": "none",
      appearance: "none",
      margin: 0,
    },
  },
  internalErr: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(2),
  },
}));
